import './App.css';
import {ThemeProvider, CSSReset, theme} from '@chakra-ui/react';
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import heroBlur from './assets/hero-blur.svg';
import Header from './components/Header/Header';
import Intro from './components/Intro/Intro';
import Features from './components/Features/Features';
import Partners from './components/Partners/Partners';
import ProtectUnderwrite from './components/ProtectUnderwrite/ProtectUnderwrite';
import Ecosystem from './components/Ecosystem/Ecosystem';
import Details from './components/Details/Details';
import PoweredByKarak from './components/PoweredByKarak/PoweredByKarak';
import Footer from './components/Footer/Footer';
import TermsAndConditions from './components/TOS/TermsAndConditions';
import PrivacyPolicy from './components/TOS/PrivacyPolicy';

const Main = styled.div`
`;

const Container = styled.div`
  position: relative;
`;

const HeroBlur = styled.img`
  position: absolute;
  width: 100vw;
  filter: brightness(80%);
`;

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Main className="main">
          <Router>
            <Switch>
              <Route exact path="/">
                <HeroBlur src={heroBlur} alt="hero-blur" />
                <Container className="container">
                  <Header />
                  <Intro />
                  <Features />
                  <Partners />
                  <ProtectUnderwrite />
                  <Ecosystem />
                  <Details />
                  <PoweredByKarak />
                  <Footer />
                </Container>
              </Route>
              <Route to path="/privacy-policy">
                <HeroBlur src={heroBlur} alt="hero-blur" />
                <Container className="container">
                  <Header />
                  <PrivacyPolicy />
                  <Footer />
                </Container>
              </Route>
              <Route to path="/terms-and-conditions">
                <HeroBlur src={heroBlur} alt="hero-blur" />
                <Container className="container">
                  <Header />
                  <TermsAndConditions />
                  <Footer />
                </Container>
              </Route>
            </Switch>
          </Router>
        </Main>
      </ThemeProvider>
    </>
  );
}

export default App;
