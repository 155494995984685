import React from 'react';
import { Box } from '@chakra-ui/react';
import Carousel from './Carousel';
import Text from './Text';
import styled from 'styled-components';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 3rem 0rem;
  margin: auto;
  margin-bottom: var(--section-bm);
  z-index: 999;
`;

const FlexBox = styled.div`
`;

const Ecosystem = () => {
  return (
    <>
      <Container>
        <FlexBox>
          <Text />
          <Carousel />
        </FlexBox>
      </Container>
    </>
  );
}

export default Ecosystem;
