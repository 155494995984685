import React from 'react';
import { Box, Link } from '@chakra-ui/react';
import styled from 'styled-components';

const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: start;
  column-gap: 6rem;
  margin-bottom: 5rem;

  @media only screen and (max-width: 790px) {
    grid-template-columns: auto auto auto auto;
    column-gap: 2.5rem;
    row-gap: var(--row-gap);
    width: 100%;
  }

  @media only screen and (max-width: 499px) {
    row-gap: var(--row-gap);
    grid-template-columns: auto auto;
    width: 100%;
  }
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.2rem;
`;

const Title = styled.p`
  color: #d7d7d7;
  font-size: 0.85rem;
  font-weight: bold;
`;

const TextLink = styled(Link)`
  color: #bdbdbd !important;
  font-size: 0.75rem !important;

  &:focus {
    outline: none;
  }
`;

const GridLink = () => {
    return (
        <GridBox>
            <FlexBox>
                <Title>Product</Title>
                <TextLink href="https://app.subseaprotocol.com" _focus={{ outline: 'none' }} isExternal>App</TextLink>
            </FlexBox>
            <FlexBox>
                <Title>Developers</Title>
                <TextLink href="https://docs.subseaprotocol.com" _focus={{ outline: 'none' }} isExternal>Documentation</TextLink>
            </FlexBox>
            <FlexBox>
                <Title>Community</Title>
                <TextLink href="https://twitter.com/SubseaProtocol" _focus={{ outline: 'none' }} isExternal>Twitter</TextLink>
                <TextLink href="https://discord.gg/WvW7r6Ekhe" _focus={{ outline: 'none' }} isExternal>Discord</TextLink>
                <TextLink href="https://t.me/SubseaProtocol" _focus={{ outline: 'none' }} isExterna>Telegram</TextLink>
            </FlexBox>
        </GridBox>
    );
}

export default GridLink;