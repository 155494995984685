import React from 'react';
import { Box } from '@chakra-ui/react';
import ethereum from '../../assets/partners/ethereum.svg';
import avalanche from '../../assets/partners/avalanche.svg';
import arbitrum from '../../assets/partners/arbitrum.svg';
import optimism from '../../assets/partners/optimism.svg';
import polygon from '../../assets/partners/polygon.svg';
import base from '../../assets/partners/base.svg';
import compound from '../../assets/partners/compound.svg';
import aave from '../../assets/partners/aave.svg';
import maker from '../../assets/partners/maker.svg';
import uniswap from '../../assets/partners/uniswap.svg';
import yearn from '../../assets/partners/yearn.svg';
import stargate from '../../assets/partners/stargate.svg';
import styled from 'styled-components';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 3rem 0rem;
  margin: auto;
  margin-bottom: var(--section-bm);
  z-index: 999;
`;

const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  row-gap: 3rem;
  column-gap: 2.5rem;
  margin: auto;
  max-width: 80%;
  width: 100%;
  z-index: 999;

  @media only screen and (min-width: 961px) and (max-width: 1244px) {
    column-gap: 2rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 960px) {
    max-width: 85%;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    grid-template-columns: 49% 49%;
    row-gap: 0.5rem;
    column-gap: 2%;
  }
`;

const ImageBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
    
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    background-color: #ffffff12;
    border-radius: 0.5rem;
    width: 100%;
    height: 5rem;
  }
`;

const Image = styled.img`
`;

const HeadingTitle = styled.h1`
  text-align: center;
  overflow-wrap: anywhere;
  color: #d7d7d7;
  font-size: 1.9rem;
  margin-bottom: 3rem;
  line-height: var(--header-line-height);
  font-weight: 500;
  z-index: 999;
`;

const images = [
  {
    src: ethereum,
    alt: "ethereum-logo"
  },
  {
    src: optimism,
    alt: "optimisim-logo"
  },
  {
    src: arbitrum,
    alt: "arbitrum-logo"
  },
  {
    src: polygon,
    alt: "polygon-logo"
  },
  {
    src: avalanche,
    alt: "avalanche-logo"
  },
  {
    src: base,
    alt: "base-logo"
  },
  {
    src: compound,
    alt: "compound-logo"
  },
  {
    src: aave,
    alt: "aave-logo"
  },
  {
    src: maker,
    alt: "maker-logo"
  },
  {
    src: uniswap,
    alt: "uniswap-logo"
  },
  {
    src: yearn,
    alt: "yearn-logo"
  },
  {
    src: stargate,
    alt: "stargate-logo"
  },
];

const Partners = () => {
  return (
    <Container>
      <HeadingTitle>Securing the best in blockchain</HeadingTitle>
      <GridBox className="partners-grid">
        {images.map((item, index) =>
          <ImageBox key={index}>
            {item.src === aave || item.src === optimism || item.src === base
              ? 
                <Image id="aave-image" className="partner-image" src={item.src} alt={item.alt} /> 
              :
                <Image className="partner-image" src={item.src} alt={item.alt} />
            }
          </ImageBox>
        )}
      </GridBox>
    </Container>
  );
}

export default Partners;