import React from 'react';
import styled from 'styled-components';

const FlexBox = styled.div`
  display: flex;
  margin-bottom: var(--section-bm);
  column-gap: 2.5rem;

  @media only screen and (max-width: 1211px) {
    column-gap: 2rem;
  }

  @media only screen and (max-width: 867px) {
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
  }
`;

const ActionBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgba(184, 154, 232, 0.4);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(0, 2, 45, 0.1) 100%);
  padding: 2rem 1rem 2rem 1rem;
  width: 48%;

  @media only screen and (max-width: 867px) {
    width: 75%;
  }

  @media only screen and (max-width: 574px) {
    width: 100%;
  }
`;

const HeadingTitle = styled.h1`
  color: white;
  font-size: 1rem;
  margin-bottom: var(--grid-item-bm);
  line-height: var(--header-line-height);
  font-weight: 500;
`;

const SubHeading = styled.p`
  font-size: var(--p2-font-size);
  font-weight: 500;
  color: #bdbdbd;
`;

const ProtectUnderwrite = () => {
  return (
    <FlexBox>
        <ActionBox>
            <HeadingTitle>Purchase protection and secure your assets.</HeadingTitle>
            <SubHeading>
              Subsea programmatically secures your assets against a wide spectrum of risks,
              exploits, and attacks. Get instant payouts with objective and transparent event assessments.
            </SubHeading>
        </ActionBox>
        <ActionBox>
            <HeadingTitle>Provide protection and earn rewards</HeadingTitle>
            <SubHeading>
                Provide protection on Subsea and earn additional rewards on already productive assets.
                Leverage dynamic risk assessment data to allocate capital safely and efficiently.
            </SubHeading>
        </ActionBox>
    </FlexBox>
  );
}

export default ProtectUnderwrite;
