import React from 'react';
import { Box } from '@chakra-ui/react';
import './Details.css'
import objective from '../../assets/details/Objective.svg';
import risk from '../../assets/details/Risk.svg';
import capital from '../../assets/details/Capital.svg';
import styled from 'styled-components';

const Container = styled(Box)`
`;

const HeadingTitle = styled.h1`
  color: #d7d7d7;
  font-size: 1.9rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 1rem;

  @media only screen and (max-width: 1368px) {
    font-size: 1.75rem;
  }

  @media only screen and (max-width: 1068px) {
    font-size: 1.5rem;
  }
`;

const FlexBox = styled(Box)`
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;

  @media only screen and (max-width: 872px) {
    column-gap: 0rem;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
`;

const ItemBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  border: 0.625px solid rgba(184, 154, 232, 0.40);
  background: linear-gradient(180deg, rgba(122, 61, 220, 0.00) 0.55%, rgba(21, 87, 255, 0.00) 95.31%);
  padding: 1.5rem;
  width: 30%;

  @media only screen and (max-width: 872px) {
    width: 25rem;
  }

  @media only screen and (max-width: 499px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 2rem;
  margin-bottom: 1.5rem;
`;

const Title = styled.h2`
  color: #d7d7d7;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const Desc = styled.p`
  color: #bdbdbd;
  font-size: var(--p2-font-size);
  font-weight: 500;
  max-width: 95%;

  @media only screen and (max-width: 1368px) {
    max-width: 100%;
  }
`;

const data = [
  {
    title: "Objective Events",
    desc: "Events are evaluated algorithmically and transparently by smart contracts rather than trusted intermediaries, allowing for instant payouts.",
    src: objective,
    alt: "objective-logo"
  },
  {
    title: "Risk Engine",
    desc: "Risk data is quantitatively modeled and tested to identify acute risk scenarios, enabling users to tailor asset allocation to their unique risk profiles.",
    src: risk,
    alt: "risk-logo"
  },
  {
    title: "Capital Efficiency",
    desc: "Market-dynamic pricing paired with infinite investment and protection permutations attempt to manage risk exposure while maximizing rewards.",
    src: capital,
    alt: "capital-logo"
  }
];

const Details = () => {
  return (
    <Container className="details-container">
      <HeadingTitle>Driven by risk assessment, transparency, and capital efficiency</HeadingTitle>
      <FlexBox>
        {data.map((item, idx) =>
          <ItemBox key={idx}>
            <Image src={item.src} alt={item.alt} />
            <Title>{item.title}</Title>
            <Desc>{item.desc}</Desc>
          </ItemBox>
        )}
      </FlexBox>
    </Container>
  );
}

export default Details;
