import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled(Box)`
  margin-bottom: 2rem;
`;

const HeadingTitle = styled.h1`
  text-align: center;
  color: #d7d7d7;
  font-size: 1.9rem;
  font-weight: 500;
  margin-bottom: 1rem;
  z-index: 999;
`;

const SubHeading = styled.p`
  font-size: var(--p2-font-size);
  font-weight: 500;
  color: #bdbdbd;
  overflow-wrap: break-word;
  text-align: center;
  width: 40%;

  @media only screen and (max-width: 767px) {
    width: 90%;
  }
`;

const Text = () => {
  return (
    <Container className="section-container" zIndex="999">
      <HeadingTitle>Ecosystem</HeadingTitle>
      <SubHeading>
        We’re securing an ever-expanding universe of financial applications
        and are always looking to integrate with new and existing solutions.
      </SubHeading>
    </Container>
  );
}

export default Text;
