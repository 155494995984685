import React from 'react';
import { Box } from '@chakra-ui/react';
import Marquee from 'react-fast-marquee';
import './Carousel.css';
import ethereum from '../../assets/carousel-logos/ethereum-carousel.svg';
import avalanche from '../../assets/carousel-logos/avalanche-carousel.svg';
import arbitrum from '../../assets/carousel-logos/arbitrum-carousel.svg';
import optimism from '../../assets/carousel-logos/optimism-carousel.svg';
import polygon from '../../assets/carousel-logos/polygon-carousel.svg';
import base from '../../assets/carousel-logos/base-carousel.svg';
import compound from '../../assets/carousel-logos/compound-carousel.svg';
import aave from '../../assets/carousel-logos/aave-carousel.svg';
import maker from '../../assets/carousel-logos/maker-carousel.svg';
import uniswap from '../../assets/carousel-logos/uniswap-carousel.svg';
import yearn from '../../assets/carousel-logos/yearn-carousel.svg';
import frax from '../../assets/carousel-logos/frax-carousel.svg';
import stargate from '../../assets/carousel-logos/stargate-carousel.svg';
import styled from 'styled-components';

const CarouselContainer = styled(Box)`
`;

const FlexBox = styled(Box)`
`;

const images = [
    {
        src: ethereum,
        alt: "ethereum-image"
    },
    {
        src: avalanche,
        alt: "avalanche-image"
    },
    {
        src: arbitrum,
        alt: "arbitrum-image"
    },
    {
        src: optimism,
        alt: "optimism-image"
    },
    {
        src: polygon,
        alt: "polygon-image"
    },
    {
        src: base,
        alt: "base-image"
    },
]

const images2 = [
    {
        src: compound,
        alt: "compound-image"
    },
    {
        src: aave,
        alt: "aave-image"
    },
    {
        src: maker,
        alt: "maker-image"
    },
    {
        src: uniswap,
        alt: "uniswap-image"
    },
    {
        src: yearn,
        alt: "yearn-image"
    },
    {
        src: frax,
        alt: "frax-image"
    },
    {
        src: stargate,
        alt: "stargate-image"
    },
]

const Carousel = () => {

    return (
      <>
        <CarouselContainer className="carousel-container">
                <FlexBox id="carousel-flexbox-one">
                    <Marquee gradientColor="transparent" direction="left" speed={60}>
                        {images.map((item, idx) =>
                            <img className="carousel-image" src={item.src} alt={item.alt} key={idx} />
                        )}
                    </Marquee>
                </FlexBox>
                
                <FlexBox id="carousel-flexbox-two">
                    <Marquee gradientColor="transparent" direction="right" speed={60}>
                        {images2.map((item, idx) =>
                            <img className="carousel-image" src={item.src} alt={item.alt} key={idx} />
                        )}
                    </Marquee>
                </FlexBox>
        </CarouselContainer>
      </>
    );

}

export default Carousel;