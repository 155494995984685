import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled(Box)`
  margin-bottom: 4rem;
`;

const HeadingTitle = styled.h1`
  text-align: center;
  color: white;
  font-size: 4rem;
  line-height: var(--header-line-height);
  font-weight: 1000;
`;

const SubHeading = styled.p`
  font-size: var(--h2-font-size);
  font-weight: var(--header-font-weight);
  color: #B4BCD0;
  overflow-wrap: break-word;
  text-align: center;
`;

const Text = () => {
  return (
    <Container zIndex="999">
      <HeadingTitle>$1+ billion</HeadingTitle>
      <SubHeading>digital assets secured</SubHeading>
    </Container>
  );
}

export default Text;
