import React from 'react';
import karakLogo from '../../assets/partners/karak.svg';
import styled from 'styled-components';
import { HStack, Link } from '@chakra-ui/react';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: var(--section-bm);
  column-gap: 2rem;

  @media only screen and (max-width: 689px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 396px) {
    column-gap: 1rem;
  }
`;

const Text = styled.h1`
  color: #ffffff;
  font-family: Spline Sans Mono;
  font-weight: 300;
  font-size: 3.5rem;

  @media only screen and (max-width: 689px) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: 475px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 396px) {
    font-size: 1.75rem;
  }
`;

const Logo = styled.img`
  width: 2.5rem;

  @media only screen and (max-width: 689px) {
    width: 2rem;
  }

  @media only screen and (max-width: 475px) {
    width: 1.75rem;
  }

  @media only screen and (max-width: 396px) {
    width: 1.5rem;
  }
`;

const PoweredByKarak = () => {
  return (
    <Container
      as={Link}
      href="https://karak.network"
      _focus={{ outline: 'none' }}
      _hover={{ textDecoration: 'none', opacity: '80%' }}
      isExternal
    >
      <Text>Powered by</Text>
      <HStack>
        <Logo src={karakLogo} alt="karak-logo" />
        <Text style={{ color: '#DF5E00' }}>Karak</Text>
      </HStack>
    </Container>
  );
}

export default PoweredByKarak;
