import React from 'react';
import Info from './Info';
import styled from 'styled-components';

const FlexBox = styled.div`
    overflow: hidden;
    margin-bottom: var(--section-bm);
`;

const Intro = () => {
    return (
        <FlexBox>
            <Info />
        </FlexBox>
    );
}

export default Intro;