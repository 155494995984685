import React from 'react';
import { Box } from '@chakra-ui/react';
import lock from '../../assets/lock.svg';
import money from '../../assets/money.svg';
import lightning from '../../assets/lightning.svg';
import styled from 'styled-components';

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  column-gap: 2rem;

  @media only screen and (max-width: 1483px) {
    column-gap: 1.5rem;
  }

  @media only screen and (max-width: 993px) {
    column-gap: 1rem;
  }

  @media only screen and (max-width: 872px) {
    column-gap: 0rem;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
`;

const SubContainer = styled(Box)`
`;

const Image = styled.img`
  width: 2rem;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 1483px) {
    width: 1.75rem;
  }

  @media only screen and (max-width: 993px) {
    width: 1.5rem;
  }

  @media only screen and (max-width: 872px) {
    width: 1.75rem;
  }
`;

const Text = styled.p`
`;

const Points = () => {
  return (
    <Container zIndex="999">
      <SubContainer className="sub-section-container">
        <Image src={lock} alt="lock" />
        <Text className="sub-section-text">
          Purchase protection against blockchain risks and network vulnerabilities.
        </Text>
      </SubContainer>
      <SubContainer className="sub-section-container">
        <Image src={money} alt="money" />
        <Text className="sub-section-text">
          Provide protection safely and earn risk-adjusted rewards.
        </Text>
      </SubContainer>
      <SubContainer className="sub-section-container">
        <Image src={lightning} alt="lightning" />
        <Text className="sub-section-text">
          Maximize capital efficiency with market-informed dynamic pricing.
        </Text>
      </SubContainer>
    </Container>
  );
}

export default Points;
