import React from 'react';
import {Box, Button, Link} from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled(Box)`
`;

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;
`;

const SubButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  column-gap: 1.5rem;
`;

const HeadingTitle = styled.h1`
  color: white;
  font-size: calc(1.5 * var(--h1-font-size));
  width: 55%;
  text-align: center;
  margin-bottom: var(--grid-item-bm);
  line-height: var(--header-line-height);
  font-weight: 500;
  filter: brightness(100%);

  @media only screen and (max-width: 1211px) {
    font-size: calc(1.5 * var(--h1-font-size));
    width: 75%;
  }

  @media only screen and (max-width: 767px) {
    font-size: calc(1.25 * var(--h1-font-size));
    width: 100%;
  }
`;

const SubHeading = styled.p`
  font-size: var(--p-font-size);
  font-weight: 500;
  width: 45%;
  text-align: center;
  margin-bottom: var(--grid-item-bm);
  color: #B4BCD0;
  overflow-wrap: break-word;
  filter: brightness(100%);

  @media only screen and (max-width: 1211px) {
    width: 75%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const Info = () => {
    return (
        <Container className="section-container" zIndex="999">
            <HeadingTitle>Risk management marketplace for digital assets</HeadingTitle>
            <SubHeading>An algorithmic, transparent, and impartial protocol that reduces dependency on trusted intermediaries.</SubHeading>
            <ButtonContainer>
              <SubButtonContainer>
                <Button
                  as={Link}
                  _hover={{textDecoration: 'none', filter: 'brightness(85%)'}}
                  _active={{color: 'transparent'}}
                  _focus={{outline: 'none', boxShadow: 'none'}}
                  px="var(--button-px)"
                  py="var(--button-py)"
                  textColor="white"
                  href="https://app.subseaprotocol.com"
                  target="_new"
                  background="var(--components-primary-purple)"
                  fontSize="var(--p-font-size)"
                  border="0.2px solid #7E9BD1"
                  borderRadius="5px"
                  width="8rem"
                >
                  Enter App
                </Button>
                <Button
                  as={Link}
                  _hover={{textDecoration: 'none', filter: 'brightness(85%)'}}
                  _active={{color: 'transparent'}}
                  _focus={{outline: 'none', boxShadow: 'none'}}
                  px="var(--button-px)"
                  py="var(--button-py)"
                  textColor="white"
                  href="https://docs.subseaprotocol.com"
                  target="_new"
                  background="linear-gradient(180deg, rgba(122, 61, 220, 0.13) 20.31%, rgba(21, 87, 255, 0.12) 100%)"
                  fontSize="var(--p-font-size)"
                  border="0.625px solid rgba(184, 154, 232, 0.40)"
                  borderRadius="5px"
                  width="8rem"
                >
                  Learn
                </Button>
              </SubButtonContainer>
            </ButtonContainer>
        </Container>
    );
}

export default Info;
