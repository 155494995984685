import React from 'react';
import Text from './Text';
import Points from './Points';
import styled from 'styled-components';

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: var(--section-bm);
`;

const Features = () => {
  return (
    <FlexBox>
      <Text />
      <Points />
    </FlexBox>
  );
}

export default Features;
