import React from 'react';
import { Box, Heading, Link } from '@chakra-ui/react';
import GridLink from './GridLink';
import styled from 'styled-components';
import logo from '../../assets/logo.svg';

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--section-bm);
`;

const Container = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

const Logo = styled.img`
  width: 1.75rem;
  margin-right: 0.7rem;
`;

const Divider = styled.hr`
  border: 0.5px solid rgba(184, 154, 232, 0.40);
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const BottomFlex = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 789px) {
    flex-direction: column;
    row-gap: 1rem;
    align-items: start;
  }
`;

const CopyFlex = styled.div`
  display: flex;
  column-gap: 2rem;
  width: 100%;

  @media only screen and (max-width: 516px) {
    flex-direction: column-reverse;
    row-gap: 1rem;
    align-items: flex-start;
  }
`;

const Footer = () => {
  return (
    <FlexBox>
      <Container>
        <Logo src={logo} alt="Subsea logo" />
        <Heading as="h1" size="md" textColor="#b4bcd0" fontFamily="Inter" fontWeight="600">Subsea</Heading>
      </Container>
      <GridLink />
      <Divider />
      <BottomFlex>
        <CopyFlex>
          <p style={{color: '#d7d7d7', fontSize: '0.75rem'}}>Subsea &copy; {new Date().getFullYear()}. All rights reserved.</p>
          <Link style={{color: '#d7d7d7', fontSize: '0.75rem'}} _focus={{ outline: 'none !important'}} href="/privacy-policy">Privacy Policy</Link>
          <Link style={{color: '#d7d7d7', fontSize: '0.75rem'}} _focus={{ outline: 'none !important'}} href="/terms-and-conditions">Terms and Conditions</Link>
        </CopyFlex>
      </BottomFlex>
    </FlexBox>
  );
}

export default Footer;
